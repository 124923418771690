import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Tooltip, Button } from '@breathelife/mui';

import { WidgetCardContainer, WidgetCardHeader } from './Styles';
import { useViewNextIncompleteFieldContext } from '../ViewNextIncompleteFieldContext';
import styled, { useTheme } from '../../../../Styles/themed-styled-components';
import { Icon } from '../../../Icons/Icon';

const WidgetCard = styled(WidgetCardHeader)`
  padding: 8px 10px;
`;

export function ViewNextIncompleteFieldWidget(): ReactElement {
  const { onClickViewNextIncompleteFieldButton, applicationHasIncompleteFields } = useViewNextIncompleteFieldContext();
  const { t } = useTranslation();
  const theme = useTheme();

  return applicationHasIncompleteFields ? (
    <WidgetCardContainer>
      <WidgetCard>
        <Box display='flex' justifyContent='flex-start'>
          <Box sx={{ whiteSpace: 'nowrap' }}>{t('assistedApplication.viewNextIncompleteFieldWidget.title')}</Box>
          <Tooltip title={t('assistedApplication.viewNextIncompleteFieldWidget.instructions')}>
            <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative', top: -10 }}>
              <Icon name='info' width='12px' height='12px' stroke={theme.colors.primary[40]} />
            </Box>
          </Tooltip>
        </Box>
        <Button variant='contained' onClick={onClickViewNextIncompleteFieldButton} sx={{ padding: '0 10px' }}>
          {t('assistedApplication.viewNextIncompleteFieldWidget.buttonLabel')}
        </Button>
      </WidgetCard>
    </WidgetCardContainer>
  ) : (
    <div />
  );
}
