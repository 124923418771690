import { Permission } from '@breathelife/types';

import { useSelector } from '../Hooks';
import { Lead } from '../Models/Lead';
import { userHasPermission } from './user';

export function isAssignedToUser(lead: Lead | undefined): boolean {
  const user = useSelector((store) => store.leadPlatform.authentication.user);
  return !!user && !!user.auth0Id && user.auth0Id === lead?.assignedToId;
}

export function canEditApplication(lead: Lead | undefined, hasCoverageAmountError = false): boolean {
  const user = useSelector((store) => store.leadPlatform.authentication.user);
  const canLaunchAllApplication = userHasPermission(user?.permissions ?? [], [
    Permission.ApplicationLaunchAll,
    // TODO: Remove ApplicationLaunch once every carrier are in prod for at least 1 day with the new
    // ApplicationLaunchMe and ApplicationLaunchAll permissions.
    Permission.ApplicationLaunch,
  ]);
  const canLaunchApplicationAssignedToUser =
    isAssignedToUser(lead) && userHasPermission(user?.permissions ?? [], [Permission.ApplicationLaunchMe]);

  return !hasCoverageAmountError && (canLaunchApplicationAssignedToUser || canLaunchAllApplication);
}

export function canOnlyReadApplication(lead: Lead | undefined, hasCoverageAmountError = false): boolean {
  const user = useSelector((store) => store.leadPlatform.authentication.user);
  const canUserEditApplication = canEditApplication(lead, hasCoverageAmountError);
  const userCanOnlyReadApplication =
    lead?.archived ||
    (!canUserEditApplication && userHasPermission(user?.permissions ?? [], [Permission.ApplicationReadAll]));

  return userCanOnlyReadApplication;
}
